var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"mb-3"},[_c('h1',{staticClass:"display-3 mb-3"},[_vm._v(_vm._s(_vm.$t("page.company.setting")))])]),_c('div',{staticClass:"mb-3"},[(_vm.loading)?_c('div',[_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)]):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-lg-6"},[(_vm.alert)?_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.alert?.status,"dismissible":"","show":Boolean(_vm.alert)}},[_vm._v(_vm._s(_vm.alert?.text))]):_vm._e()],1)]),_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("form.loyal-type")))]),_c('div',{staticClass:"input-group"},[_c('b-form-select',{staticClass:"form-control form-control-sm text-capitalize",attrs:{"size":"sm","options":_vm.discountTypes},model:{value:(_vm.form.discount_type),callback:function ($$v) {_vm.$set(_vm.form, "discount_type", $$v)},expression:"form.discount_type"}})],1)]),(String(_vm.form.discount_type) === '1')?[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("form.percents")))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.percents),expression:"form.percents"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && _vm.$v.form.percents.$error,
                      },domProps:{"value":(_vm.form.percents)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "percents", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.percents.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.field-req-integer"))+" ")]):_vm._e()])])]:_vm._e(),(String(_vm.form.discount_type) === '2')?[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("form.count_of_cups")))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.count_of_cups),expression:"form.count_of_cups"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.form.count_of_cups.$error,
                      },domProps:{"value":(_vm.form.count_of_cups)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "count_of_cups", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.count_of_cups.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.field-req-integer"))+" ")]):_vm._e()])])]:_vm._e(),(String(_vm.form.discount_type) === '3')?[_c('div',{staticClass:"form-group mb-3"},[_c('div',{staticClass:"input-group input-group-merge"},[_c('b-form-checkbox',{attrs:{"value":"1","unchecked-value":"0"},model:{value:(_vm.form.writte_of_drink_price),callback:function ($$v) {_vm.$set(_vm.form, "writte_of_drink_price", $$v)},expression:"form.writte_of_drink_price"}},[_vm._v(" "+_vm._s(_vm.$t("form.writte_of_drink_price"))+" ")])],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("form.bonuses_per_cup")))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.bonuses_per_cup),expression:"form.bonuses_per_cup"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.form.bonuses_per_cup.$error,
                      },domProps:{"value":(_vm.form.bonuses_per_cup)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "bonuses_per_cup", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.bonuses_per_cup.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.field-req-integer"))+" ")]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("form.bonuses_for_writte_of")))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.bonuses_for_writte_of),expression:"form.bonuses_for_writte_of"}],staticClass:"form-control",class:{
                        'is-invalid':
                          _vm.submitted && _vm.$v.form.bonuses_for_writte_of.$error,
                      },domProps:{"value":(_vm.form.bonuses_for_writte_of)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "bonuses_for_writte_of", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.bonuses_for_writte_of.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.field-req-integer"))+" ")]):_vm._e()])])]:_vm._e()],2),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("form.prepayment_discount")))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.prepayment_discount),expression:"form.prepayment_discount"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.form.prepayment_discount.$error,
                    },domProps:{"value":(_vm.form.prepayment_discount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "prepayment_discount", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.prepayment_discount.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("form.field-req-integer"))+" ")]):_vm._e()])])])]),_c('div',{staticClass:"mt-3 text-center text-md-left"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.trySubmit}},[_vm._v(" "+_vm._s(_vm.$t("btn.save-changes"))+" ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }