<template>
  <Layout>
    <div class="mb-3">
      <h1 class="display-3 mb-3">{{ $t("page.company.setting") }}</h1>
    </div>

    <div class="mb-3">
      <div v-if="loading">
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </div>

      <div v-else class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8 col-lg-6">
              <b-alert
                v-if="alert"
                :variant="alert?.status"
                class="mt-3"
                dismissible
                :show="Boolean(alert)"
                >{{ alert?.text }}</b-alert
              >
            </div>
          </div>

          <form action="#" @submit.prevent="handleSubmit">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label class="required">{{ $t("form.loyal-type") }}</label>
                  <div class="input-group">
                    <b-form-select
                      v-model="form.discount_type"
                      size="sm"
                      :options="discountTypes"
                      class="form-control form-control-sm text-capitalize"
                    ></b-form-select>
                  </div>
                </div>

                <!-- type 1
                  percents -->
                <template v-if="String(form.discount_type) === '1'">
                  <div class="form-group mb-3">
                    <label class="required">{{ $t("form.percents") }}</label>
                    <div class="input-group input-group-merge">
                      <input
                        v-model="form.percents"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && $v.form.percents.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.form.percents.$error"
                        class="invalid-feedback"
                      >
                        {{ $t("form.field-req-integer") }}
                      </div>
                    </div>
                  </div>
                </template>

                <!-- type 2
                  count_of_cups -->
                <template v-if="String(form.discount_type) === '2'">
                  <div class="form-group mb-3">
                    <label class="required">{{
                      $t("form.count_of_cups")
                    }}</label>
                    <div class="input-group input-group-merge">
                      <input
                        v-model="form.count_of_cups"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.count_of_cups.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.form.count_of_cups.$error"
                        class="invalid-feedback"
                      >
                        {{ $t("form.field-req-integer") }}
                      </div>
                    </div>
                  </div>
                </template>

                <!-- type 3 -->
                <!-- 
                    //   writte_of_drink_price: "", bool
                    //   bonuses_per_cup: "", int
                    //   bonuses_for_writte_of: "", int
                    
                  -->
                <template v-if="String(form.discount_type) === '3'">
                  <div class="form-group mb-3">
                    <div class="input-group input-group-merge">
                      <b-form-checkbox
                        v-model="form.writte_of_drink_price"
                        value="1"
                        unchecked-value="0"
                      >
                        {{ $t("form.writte_of_drink_price") }}
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label class="required">{{
                      $t("form.bonuses_per_cup")
                    }}</label>
                    <div class="input-group input-group-merge">
                      <input
                        v-model="form.bonuses_per_cup"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.bonuses_per_cup.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.form.bonuses_per_cup.$error"
                        class="invalid-feedback"
                      >
                        {{ $t("form.field-req-integer") }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label class="required">{{
                      $t("form.bonuses_for_writte_of")
                    }}</label>
                    <div class="input-group input-group-merge">
                      <input
                        v-model="form.bonuses_for_writte_of"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.bonuses_for_writte_of.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.form.bonuses_for_writte_of.$error"
                        class="invalid-feedback"
                      >
                        {{ $t("form.field-req-integer") }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <label class="required">{{
                    $t("form.prepayment_discount")
                  }}</label>
                  <div class="input-group input-group-merge">
                    <input
                      v-model="form.prepayment_discount"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.form.prepayment_discount.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.prepayment_discount.$error"
                      class="invalid-feedback"
                    >
                      {{ $t("form.field-req-integer") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3 text-center text-md-left">
              <button
                class="btn btn-primary"
                type="submit"
                :disabled="trySubmit"
              >
                {{ $t("btn.save-changes") }}
              </button>
            </div>
          </form>

          <!-- <Debuginfo>
            <div>
              <pre>setting: {{ setting }}</pre>
            </div>
            <div>
              <pre>discountTypes: {{ discountTypes }}</pre>
            </div>
            <div>
              <pre>form: {{ form }}</pre>
            </div>
          </Debuginfo> -->
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";
import Layout from "@/views/layouts/main";

import { required, integer } from "vuelidate/lib/validators";

export default {
  metaInfo() {
    return {
      title: this.$t("app.title") + " | " + this.$t("page.company.setting"),
    };
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: false,
      alert: null,
      wait: false,

      setting: null,

      discount_type: "",

      // discount_type, bonuses_per_cup (if bonuses_per_cup), writte_of_drink_price (if bonuses_per_cup), bonuses_for_writte_of (if bonuses_per_cup), count_of_cups (if count_of_cups), percents, prepayment_discount
      // form: {
      //   // 3
      //   bonuses_per_cup: "",
      //   bonuses_for_writte_of: "",
      //   writte_of_drink_price: "",
      //   // ??
      //   count_of_cups: "",
      //   percents: "",
      //   prepayment_discount: "",
      // },
      form: null,

      submitted: false,
      trySubmit: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData(false);
  },
  computed: {
    discountTypes() {
      if (this.setting?.discountTypes) {
        return Object.entries(this.setting.discountTypes).map((t) => {
          return {
            value: t[0] || "",
            text: t[1] || "",
          };
        });
      }

      return [];
    },
    formRules() {
      let rules = {};

      if (String(this.form?.discount_type) === "1") {
        rules.percents = {
          required,
          integer,
        };
      }

      if (String(this.form?.discount_type) === "2") {
        rules.count_of_cups = {
          required,
          integer,
        };
      }

      if (String(this.form?.discount_type) === "3") {
        rules.bonuses_per_cup = {
          required,
          integer,
        };

        rules.bonuses_for_writte_of = {
          required,
          integer,
        };
      }

      rules.prepayment_discount = {
        required,
        integer,
      };

      return rules;
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ["getCompanySetting", "setCompanySetting"]),
    async loadData(silent = true) {
      try {
        if (this.wait) {
          return;
        }

        this.wait = true;

        if (!silent) {
          this.loading = true;
        }

        this.alert = null;

        const req = await this.getCompanySetting();
        // console.log("req: ", req);
        this.setting = req.data || null;
        this.initForm(this.setting);
      } catch (error) {
        console.log("loadData Error: ", error);

        this.alert = {
          status: "danger",
          text: this.$t("msg.error"),
        };

        if (error.response?.status && error.response?.data?.message) {
          this.alert.text = `[${error.response.status}] ${error.response.data.message}`;
        }
      } finally {
        this.loading = false;
        this.wait = false;
      }
    },
    // async submitForm() {
    //   await this.loadData();

    //   this.alert = {
    //     status: "success",
    //     text: this.$t("msg.data-update-success"),
    //   };
    // },
    initForm(data = null) {
      // copy setting type and model
      if (data?.discount_type && data?.model) {
        this.form = {
          discount_type: data.discount_type,
          prepayment_discount: data.prepayment_discount,
          ...data.model,
        };
      }
    },
    async handleSubmit() {
      this.submitted = true;
      this.error = "";

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        console.log("handleSubmit...");
        this.trySubmit = true;
        this.alert = null;

        try {
          // discount_type, bonuses_per_cup (if bonuses_per_cup), writte_of_drink_price (if bonuses_per_cup), bonuses_for_writte_of (if bonuses_per_cup), count_of_cups (if count_of_cups), percents, prepayment_discount
          const params = {
            discount_type: this.form.discount_type,
            prepayment_discount: this.form.prepayment_discount,
          };

          if (String(this.form?.discount_type) === "1") {
            params.percents = this.form.percents;
          }

          if (String(this.form?.discount_type) === "2") {
            params.count_of_cups = this.form.count_of_cups;
          }

          if (String(this.form?.discount_type) === "3") {
            params.writte_of_drink_price = this.form.writte_of_drink_price;
            params.bonuses_per_cup = this.form.bonuses_per_cup;
            params.bonuses_for_writte_of = this.form.bonuses_for_writte_of;
          }

          await this.setCompanySetting(params);

          this.alert = {
            status: "success",
            text: this.$t("msg.data-update-success"),
          };
        } catch (error) {
          console.log("handleSubmit Error: ", error);

          this.alert = {
            status: "danger",
            text: this.$t("msg.error"),
          };

          if (error.response?.status && error.response?.data?.message) {
            this.alert.text = `[${error.response.status}] ${error.response.data.message}`;
          }
        } finally {
          this.trySubmit = false;
        }
      }
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss"></style>
